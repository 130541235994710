import { useState, useEffect } from "react";
import React from 'react';
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import de from 'react-phone-number-input/locale/de.json'
import Grid from '@material-ui/core/grid'
import { Link, Navigate } from "react-router-dom";
import ChangeableTextComp from "./changeableTextComp"



const Users = (props) => {
    /*   console.log(props.history) */
    const [error, setError] = useState("");
    const [privateData, setPrivateData] = useState("");
    const [received, setReceived] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [agents, setAgents] = useState(false)





    useEffect(() => {
        if (props.auth == "false") {
            console.log("pushtest")
            console.log(props.auth)
            window.location.href = '/login';
        }



    }, []);


    useEffect(() => {
        axios.get('/api/private/Agents', {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(el => {
            setAgents(el.data.message)
            console.log(el)
            if (el.data.success == true) {
                setReceived(true)
            }
        })

    }, []);




    const handleDelete = (e) => {
        if (window.confirm('Möchtest du wirklich löschen?')) {
            console.log(e)
            const req = axios.delete(`/api/private/agents/${e._id}`, {
                headers: {
                    "Content-Type": "application/json",
                }
            })

                .then((response) => {
                    setAgents(prevState => prevState.filter(item => item._id !== e._id));
                })
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }


    }

    const handleAdd = () => {

        let vorname = prompt("Vorname", "");
        let nachname = prompt("Nachname", "");
        let gmail = prompt("Gmail", "");
        //  let content = prompt("Bitte gib hier den neuen Text ein", ""); 

        if (vorname != null && nachname != null && gmail != null) {
            let obj = {
                firstName: vorname,
                lastName: nachname,
                email: gmail
            }


            if (!props.Text) {
                const req = axios.post(`/api/private/agents`, obj, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
                    .then((response) => {
                        console.log(response.data)
                        obj._id = response.data._id
                        setAgents(prevState => {
                            return [...prevState, obj]
                        })
                    })
            }
        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }
    }


    if (props.auth !== "" && props.auth !== "false" && props.Texts) {
        return (
            <div>

                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>

                    <div id="content" />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <header>

                            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                                <div className="navbar-brand" href="#">drc-ce</div>
                                <button onClick={() => setMobile(prevState => !prevState)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div style={{ display: mobile ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarCollapse">
                                    <ul className="navbar-nav mr-auto">
                                        {props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/hotline-admin"} >Hotline</Link>
                                            </li>
                                        ) :
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/hotline"} >Hotline</Link>
                                            </li>
                                        }

                                        {props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/bilder-admin"} >Bilder</Link>
                                            </li>
                                        ) :
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/bilder"} >Bilder</Link>
                                            </li>
                                        }

                                        {props.adminrights && props.adminview ? (
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/${props.RandomPath}-admin`} >Anrufer</Link>
                                            </li>
                                        ) : ""}
                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item active">
                                                <Link className="nav-link" to={`/user-admin`} >Agents</Link>
                                            </li>
                                        ) : ""}
                                           {props.adminrights && props.adminview ? (

<li className="nav-item">
    <Link className="nav-link" to={`/email`} >Email</Link>
</li>
) : ""}
                                    </ul>
                                    

                                </div>
                            </nav>
                        </header>
                        <main role="main">
                            <div style={{ marginTop: 100 }} className="col-lg-10 mx-auto">


                                <div className="row align-items-start" >
                                    <table class="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">Vorname</th>
                                                <th scope="col">Nachname</th>
                                                <th scope="col">Gmail</th>

                                                <th>  <i onClick={() => handleAdd()} style={{ cursor: "pointer" }} class="fa fa-plus" aria-hidden="true"></i>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {agents && agents.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr>
                                                            <th scope="row">{index + 1}</th>
                                                            <td>{item.firstName}</td>
                                                            <td>{item.lastName}</td>
                                                            <td>{item.email}</td>
                                                            <td>  <i onClick={() => handleDelete(item)} style={{ cursor: "pointer" }} class="fa fa-trash" aria-hidden="true"></i>
                                                            </td>
                                                        </tr>

                                                    </>
                                                )
                                            })}
                                        </tbody>
                                    </table>


                                </div>



                            </div>
                        </main>
                    </div>

                </div >

            </div>

        )
    } else {
        return ""
    }
}
export default Users;


