import { useState, useEffect } from "react";
import axios from "axios";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import Button from '@mui/material/Button';
import { Link, Navigate } from "react-router-dom";
import ChangeableTextComp from "./changeableTextComp"


const PrivateScreen = (props) => {
  console.log(props.Texts)
  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");
  const [filesField, setFilesField] = useState(false);
  const [textField, setTextField] = useState("");
  const [Signature, setSignature] = useState(false)
  const [language, setLanguage] = useState(false)
  const [Radio, setRadio] = useState(false);
  const [successfull, setSuccessfull] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [mobile, setMobile] = useState(false)


  function onlyLettersAndSpaces(str) {
    console.log(/^[A-Za-z0-9 \-_.äöüß etc]*$/.test(str))
    return /^[A-Za-z0-9 \-_.äöüß etc]*$/.test(str);
  }


  const handleTextChange = (e) => {
    if (e.target.value == "") {
      setDisabled(true)
    } else if (!onlyLettersAndSpaces(e.target.value)) {
      setDisabled(true)
    }
    else {
      setDisabled(false)
      //setDisabled(false)
    }
    setTextField(e.target.value)
  }

  const handleFileChange = (e) => {
    setFilesField(e.target.files[0])
  }

  const upload = (e) => {
    console.log("test")
    const FilesField = filesField
    console.log(filesField)
    const name = textField
    console.log(textField)
    const file = new FormData()
    file.append('file', FilesField)
    file.append("NameField", name)
    console.log(file)
    axios.post('/api/s3/upload_file', file, {
      /*  headers: {
         "Content-Type": "application/json",
       } */
    }).then(el => {
      console.log(el.data)
      if (el.data.success == true) {
        console.log(el.data)
        setSuccessfull(true)
        /*    document.getElementById("hochgeladen").style.display = "block";
           document.getElementById("show").style.display = "none"; */
      }
    })

  }
  

  if (true) {


    return (
      <>
        <div style={{ display: "flex", justifyContent: "center", position: "absolute", zIndex: 1000 }} id="meetingSDKElement"></div>
        <div style={{ width: "100vw" }} >asd</div>

        <div>

          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <header>
              <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <a className="navbar-brand" href="#">drc-ce</a>
                <button onClick={() => setMobile(prevState => !prevState)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                  aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>
                {/*  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button> */}
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav mr-auto">
                    <li className="nav-item active">
                      <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a>
                    </li>
                  </ul>

                </div>
              </nav>
            </header>
            <div id="content" />
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
              <header>
                <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                  <a className="navbar-brand" href="#">drc-ce</a>
                  <div style={{ display: mobile ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarCollapse">


                    <ul className="navbar-nav mr-auto">

                      {props.adminrights &&
                        <>


                          <li className="nav-item">
                            <Link auth={props.auth} className="nav-link" to={"/hotline-admin"} >Hotline</Link>
                          </li>


                          <li className="nav-item">
                            <Link auth={props.auth} className="nav-link" to={"/bilder-admin"} >Bilder</Link>
                          </li>

                          {props.adminrights && props.adminview ? (
                            <li className="nav-item active">
                              <Link className="nav-link" to={`/${props.RandomPath}`} >Anrufer</Link>
                            </li>
                          ) : ""}

                          {props.adminrights && props.adminview ? (

                            <li className="nav-item">
                              <Link className="nav-link" to={`/user-admin`} >Agents</Link>
                            </li>
                          ) : ""}
                          {props.adminrights && props.adminview ? (

                            <li className="nav-item">
                              <Link className="nav-link" to={`/email`} >Email</Link>
                            </li>
                          ) : ""}
                        </>
                      }
                      {/*   <a className="nav-link" href="#">Home <span className="sr-only">(current)</span></a> */}

                    </ul>
                    {props.adminrights && (
                      <ul class="navbar-nav ml-auto">
                        <li className="nav-item">
                          <Link className="nav-link" to={`/${props.RandomPath}`} >Anrufer View</Link>
                        </li>
                        <li className="nav-item">
                          <Link className="nav-link" to={`/${props.RandomPath}-admin`} >Admin View</Link>
                        </li>
                      </ul>
                    )}



                  </div>
                </nav>
              </header>
              <main role="main">
                <div style={{ marginTop: 100 }} className="col-lg-4 mx-auto">


                  <div className="row align-items-start" >
                    <div style={{ display: "flex", justifyContent: "space-evenly" }} className="col-sm-10 col-md-7 col-lg-12 mx-auto">
                      <button onClick={(e) => setLanguage("Deutsch")} style={{ opacity: language == "Deutsch" ? '0.8' : '1' }} className="mt-2 btn btn-primary but"  >
                        Deutsch
                      </button>
                      {/* <button onClick={(e) => setLanguage("Englisch")} style={{ opacity: language == "Englisch" ? '0.8' : '1' }} className="mt-2 btn btn-primary but" >
                        English
                      </button> */}
                      <button onClick={(e) => setLanguage("Russisch")} style={{ opacity: language == "Russisch" ? '0.8' : '1' }} className="mt-2 btn btn-primary but"  >
                      Русский
                      </button>





                    </div>

                  </div>
                  {/*   <div className="row">




                     <div className="col-lg-4">
                  <h2>Shortcut</h2>
                  <p>Erklärung</p>
                  <p><a className="btn btn-secondary" href="#" role="button">View details »</a></p>
                </div>  
              </div> */}

                  {language &&
                    <>
                      <div className="row">
                        {/* <div style={{ display: "flex", justifyContent: "center" }} className="col-sm-10 col-md-6 col-lg-10 mx-auto">

                          {language == "Deutsch" ?
                            <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                              <ChangeableTextComp br={true} language={"all"} setTexts={props.setTexts} name={"HotlineDeutsch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineDeutsch} />
                            </h5>
                            : language == "Englisch" ?
                              <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                                <ChangeableTextComp br={true} language={"all"} setTexts={props.setTexts} name={"HotlineEnglisch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineEnglisch} />
                              </h5>

                              : language == "Russisch" ?
                                <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                                  <ChangeableTextComp br={true} language={"all"} setTexts={props.setTexts} name={"HotlineRussisch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineRussisch} />
                                </h5>


                                : ""
                          }     </div> */}
                        <div style={{ display: "flex", justifyContent: "center" }} className="col-sm-10 col-md-6 col-lg-10 mx-auto">

                          <div>

                            <br />
                            <h2 style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>{language == "Deutsch" ? "Bilder Upload" : language == "Englisch" ? "Picture Upload" : "Загрузить Изображение"}</h2>

                            {language == "Deutsch" ?
                              <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                                <ChangeableTextComp show={false} br={true} language={"all"} setTexts={props.setTexts} name={"HotlineBildDeutsch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineBildDeutsch} />
                              </h5>
                             /*  : language == "Englisch" ?
                                <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                                  <ChangeableTextComp show={false} br={true} language={"all"} setTexts={props.setTexts} name={"HotlineBildEnglisch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineBildEnglisch} />
                                </h5> */

                                : language == "Russisch" ?
                                  <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                                    <ChangeableTextComp show={false} br={true} language={"all"} setTexts={props.setTexts} name={"HotlineBildRussisch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineBildRussisch} />
                                  </h5>


                                  : ""
                            }

                            {filesField ?
                              <h4 style={{ textAlign: "center", marginTop: 20, marginBottom: 20 }}>{language == "Deutsch" && props.Texts.HotlineBildDeutsch ? props.Texts.HotlineBildDeutsch.Content :
                                language == "Englisch" && props.Texts.HotlineBildEnglisch ?
                                  props.Texts.HotlineBildEnglisch.Content : language == "Russisch" && props.Texts.HotlineBildRussisch ? props.Texts.HotlineBildRussisch.Content : ""}</h4>
                           : "" }
                             <form style={{ textAlign: "center" }}>
                              {filesField &&
                                <>
                                  <label htmlFor="fname">{/* {language == "Deutsch" ? "Name" : language == "Englisch" ? "Name" : "Имя"} */}</label>
                                  <input style={{ width: "192px" }} /* className="ml-3" */ onChange={e => handleTextChange(e)} value={textField} type="text" id="fname" name="fname" /><br />
                                </>}

                              {!filesField && !successfull ?
                                <label style={{ marginBottom: '0.5rem', width: "250px" }} id="label" className="mt-2 btn btn-secondary">
                                  <input onChange={handleFileChange} id="file" type="file" />
                                  {language == "Deutsch" ? "Bild Auswählen" : language == "Englisch" ? "Choose Picture" : "Выбрать Изображение"}
                                </label>
                                : filesField && !successfull ?
                                  disabled ?
                                    <input disabled={disabled} id="show" style={{ cursor: "initial", marginTop: "0.5rem", marginBottom: '0.5rem', width: "250px" }} className="btn btn-secondary" type="button" defaultValue={language == "Deutsch" ? "Hochladen" : language == "Englisch" ? "Upload" : "загрузить"} />
                                    :
                                    <input onClick={() => upload()} id="show" style={{ marginTop: "0.5rem", marginBottom: '0.5rem', width: "250px" }} className="btn btn-secondary" type="button" defaultValue={language == "Deutsch" ? "Hochladen" : language == "Englisch" ? "Upload" : "загрузить"} />

                                  : ""}</form>
                            {successfull &&
                              <div style={{
                                margin: "auto",

                                display: "block", cursor: "inherit", width: "250px", backgroundColor: "#2fbf4d"
                              }} className="mt-2 btn btn-primary" id="hochgeladen">{language == "Deutsch" ? "Erfolgreich Hochgeladen" : language == "Englisch" ? "Uploaded Successfully" : "Загружено Успешно "} </div>

                            }
                            <div style={{ display: 'none' }} id="hochgeladen">{language == "Deutsch" ? "Erfolgreich Hochgeladen" : language == "Englisch" ? "Uploaded Successfully" : "Загружено Успешно "} </div>



                          </div>
                        </div>
                      </div>


                      
                    </>
                  }
                </div>

              </main>
            </div>

          </div></div>
      </>
    )
  } else {
    return ""
  }
};

export default PrivateScreen;
