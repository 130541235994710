import { useState, useEffect, useRef } from "react";
import React from 'react';
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import de from 'react-phone-number-input/locale/de.json'
import Grid from '@material-ui/core/grid'
import { Link, Navigate } from "react-router-dom";
import ChangeableTextComp from "./changeableTextComp"
import EmailCheck from "./EmailCheck"
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getText } from "react-quill";
import DOMPurify from 'dompurify';





const Hotline = (props) => {
    /*   console.log(props.history) */
    const [error, setError] = useState("");
    const [privateData, setPrivateData] = useState("");
    const [Radio, setRadio] = useState(false);

    const [focused, setFocused] = React.useState(false)
    const [saved, setSaved] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [Vorlagen, setVorlagen] = useState([])
    const [active, setActive] = useState(false)
    const [emailAdresses, setEmailAdresses] = useState([])
    const [activeMessage, setActiveMessage] = useState("")
    const [Quill, setQuill] = useState([])
    const [QuillSubject, setQuillSubject] = useState([])
    const [QuillSubjectString, setQuillSubjectString] = useState([])
    const [load, setLoad] = useState(true)
    const [filesField, setFilesField] = useState("")
    const [Anhang, setAnhang] = useState("")





    const hiddenFileInput = useRef(null);
    const hiddenFileInput2 = useRef(null);

    const handleClickHidden = type => {
        if (type == "CSV") {
            hiddenFileInput.current.click();
        } if (type == "Anhang") {
            hiddenFileInput2.current.click();
        }
    };

    const handleChangeCSV = event => {
        event.preventDefault();
        console.log(event.target.files)
        setFilesField(event.target.files[0])
    };
    const handleChangeAnhang = event => {
        event.preventDefault();
        console.log(event.target.files)
        setAnhang(event.target.files)
    };


    useEffect(() => {
        if (props.auth == "false") {
            console.log("pushtest")
            console.log(props.auth)
            window.location.href = '/login';
        }



    }, []);



    useEffect(() => {
        let test = Object.values(props.Texts)
        test = test.filter(item => {
            return item.Language == Radio
        });
        console.log(test)
        setVorlagen(test)

    }, [Radio, props.Texts]);





    const newMessagesSave = (e) => {
        console.log(activeMessage)
        console.log(active)

        let plaintext = new DOMParser().parseFromString(Quill, 'text/html')
        plaintext = plaintext.body.textContent 

        let someText = QuillSubjectString.replace(/(\r\n|\n|\r)/gm, "");
        const data = new FormData()
        var json_arr = JSON.stringify(emailAdresses);
        console.log(json_arr)
        data.append('Adresses',  json_arr)
        data.append('Message', Quill)
        data.append('Plain', plaintext)
        data.append('Subject', someText)
        data.append('uploadCSV', filesField)
        for (let i = 0 ; i < Anhang.length ; i++) {
            data.append("uploadAnhang", Anhang[i]);
        }

  
/*         let obj = {
            Adresses: emailAdresses,
            Message: Quill,
            Subject: someText,
            uploadCSV: filesField,
            uploadAnhang: Anhang
        }
        console.log(obj) */


        const req = axios.post(`/api/private/sendEmail`, data, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((response) => {
                console.log(response)
                setSaved(true)

            })



    }

    let modules = {
        toolbar: [

            ['bold', 'italic', 'underline'],


            ['clean']
        ],
    }

    let formats = [
        'header',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        /* 'link', 'image' */
    ]


    const handleQuill = (e) => {
        setSaved(false)
        setQuill(e)
        /*      setSaved(false) */
    }

    const handleQuillSubject = (content, delta, source, editor) => {

        const text = editor.getText(content);
        setSaved(false)
        setQuillSubject(content)
        setQuillSubjectString(text)

    }







    if (props.auth !== "" && props.auth !== "false" && props.Texts) {
        return (
            <div>

                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>

                    <div id="content" />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <header>

                            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                                <div className="navbar-brand" href="#">drc-ce</div>
                                <button onClick={() => setMobile(prevState => !prevState)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div style={{ display: mobile ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarCollapse">
                                    <ul className="navbar-nav mr-auto">
                                        {props.adminview ? (

                                            <li className="nav-item ">
                                                <Link className="nav-link" to={"/hotline-admin"} >Hotline</Link>
                                            </li>
                                        ) :
                                            <li className="nav-item ">
                                                <Link className="nav-link" to={"/hotline"} >Hotline</Link>
                                            </li>
                                        }

                                        {props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/bilder-admin"} >Bilder</Link>
                                            </li>
                                        ) :
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/bilder"} >Bilder</Link>
                                            </li>
                                        }



                                        {props.adminrights && props.adminview ? (
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/${props.RandomPath}-admin`} >Anrufer</Link>
                                            </li>
                                        ) : ""}

                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/user-admin`} >Agents</Link>
                                            </li>
                                        ) : ""}
                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/email`} >Email</Link>
                                            </li>
                                        ) : ""}
                                    </ul>
                                    {props.adminrights && (
                                        <ul class="navbar-nav ml-auto">
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/hotline"} >Hotline View</Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/hotline-admin"} >Admin View</Link>
                                            </li>
                                        </ul>
                                    )}

                                </div>
                            </nav>
                        </header>
                        <main role="main">
                            <div style={{ marginTop: 100 }} className="col-lg-4 mx-auto">






                                <div className="col-sm-8 col-md-6 col-lg-10 mx-auto">
                                    <Box
                                        component="form"
                                        sx={{
                                            '& .MuiTextField-root': { m: 1, mt: 3, width: '25ch' },
                                        }}
                                        noValidate
                                        autoComplete="off"
                                    >

                                        <EmailCheck setEmailAdresses={setEmailAdresses} emailAdresses={emailAdresses} />

                                        <input
                                            style={{ width: "0px" }}
                                            class="ZeroWidth"
                                            key="uploadCSV"
                                            ref={hiddenFileInput}
                                            accept=".csv"

                                            type="file" name="uploadCSV" onChange={handleChangeCSV} />





                                        <div style={{ width: "unset" }} onClick={() => handleClickHidden("CSV")} className="m-2 btn btn-primary" variant="contained" >
                                            CSV Upload
                                        </div>
                                        <h6 style={{ padding: "4px", fontSize: "12px" }}>Die CSV ist im Format <br/>
                                        Spalte 1: Email<br/>
                                        Spalte 2: Vorname<br/>
                                        Spalte 3: Nachname<br/>
                                        Spalte 4: Gmail<br/>
                                        Die erste Reihe wird ignoriert. Benutze  z.B. $&#123;Vorname&#125; für dynamischen Text</h6>



                                        <div style={{ marginTop: "10px", }}>


                                            <ReactQuill
                                                placeholder="Betreff"
                                                modules={{ toolbar: false }}
                                                formats={{}}
                                                theme="snow"
                                                value={QuillSubject}
                                                onChange={handleQuillSubject}
                                            />

                                        </div>

                                        <div style={{ marginTop: "10px" }}>
                                            <ReactQuill
                                                id="firstQuill"
                                                placeholder="Inhalt"
                                                modules={modules}
                                                formats={formats}
                                                theme="snow"
                                                value={Quill}
                                                onChange={(e) => handleQuill(e)}
                                            />

                                        </div>

                                        <input
                                            style={{ width: "0px" }}
                                            class="ZeroWidth"
                                            key="uploadAnhang"
                                            ref={hiddenFileInput2}
                                            accept="application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*"
                                            multiple="multiple"
                                            type="file" name="uploadAnhang" onChange={handleChangeAnhang} />





                                        <div style={{ width: "unset" }} onClick={() => handleClickHidden("Anhang")} className="m-2 btn btn-primary" variant="contained" >
                                            Anhänge
                                        </div>

                                        {/* 
                                        <TextField
                                            inputProps={{ spellCheck: 'false' }}
                                            id="outlined-multiline-static"

                                            multiline
                                            rows={6}
                                            value={activeMessage}
                                            onChange={(e) => handleActiveChange(e)}
                                            variant="filled"
                                        /> */}



                                    </Box>
                                    <Box
                                        sx={{ mt: 2 }}
                                    >



                                    </Box>
                                    <Grid container justify="flex-end">


                                        {(emailAdresses.length == 0 && filesField == "") || Quill == "" || QuillSubject == "" ?
                                            <button style={{ width: "unset", pointerEvents: "none" }} className="m-2 btn btn-secondary" variant="contained" >

                                                Senden
                                            </button>
                                            :

                                            !saved ?
                                                <button style={{ width: "unset" }} onClick={() => newMessagesSave()} className="m-2 btn btn-primary" variant="contained" >

                                                    Senden
                                                </button>
                                                :
                                                <button style={{ width: "200px", backgroundColor: "#2fbf4d", cursor: "initial" }} className="mt-2 btn btn-primary"  >
                                                    Gesendet
                                                </button>
                                        }


                                    </Grid>






                                </div>


                            </div>
                        </main>
                    </div>

                </div ></div >


        )
    } else {
        return ""
    }
}
export default Hotline;


