import React from "react";
import ReactDOM from "react-dom";
import { useState, useEffect } from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';


const Hotline = (props) => {
  /*  const [items, props.setEmailAdresses] = useState([]); */
  const [value, setvalue] = useState("");
  const [error, seterror] = useState(null);
  const [expanded, setExpanded] = React.useState(false);

  const handleChangeAcc = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const handleKeyDown = evt => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      var valueVar = value.trim();

      if (valueVar && isValid(valueVar)) {
        props.setEmailAdresses(prevState => [...prevState, value])
        setvalue("")
      }
    }
  };

  const handleChange = evt => {
    setvalue(evt.target.value)
    seterror(null)

  };

  const handleDelete = item => {

    props.setEmailAdresses(props.emailAdresses.filter(i => i !== item))
  };

  const handlePaste = evt => {
    evt.preventDefault();

    var paste = evt.clipboardData.getData("text");
    var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g);
    console.log()
    if (emails) {
      var toBeAdded = emails.filter(email => !isInList(email));
      props.setEmailAdresses(prevState => [...prevState, ...toBeAdded])

    }
  };

  function isValid(email) {
    let errorVar = null;

    if (isInList(email)) {
      errorVar = `${email} has already been added.`;
    }

    if (!isEmail(email)) {
      errorVar = `${email} is not a valid email address.`;
    }

    if (errorVar) {
      seterror(errorVar)

      return false;
    }

    return true;
  }

  function isInList(email) {
    return props.emailAdresses.includes(email);
  }

  function isEmail(email) {
    return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email);
  }


  return (
    <>
      {props.emailAdresses.length > 0 &&
        <Accordion expanded={expanded === 'panel1'} onChange={handleChangeAcc('panel1')}>
          <AccordionSummary
            expandIcon={<i class="fa fa-caret-down"></i>}
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <>{props.emailAdresses.length == 1 ? props.emailAdresses.length + " " +  "Adresse" : props.emailAdresses.length > 1 ? props.emailAdresses.length + " " +  "Adressen"  : ""} </>

          </AccordionSummary>
          <AccordionDetails>
          {props.emailAdresses.map(item => (
        <div className="tag-item" key={item}>
          {item}
          <button
            type="button"
            className="button"
            onClick={() => handleDelete(item)}
          >
            &times;
          </button>
        </div>
      ))}

          </AccordionDetails>
        </Accordion>
      }

      

      <input
        className={"input " + (error && " has-error")}
        value={value}
        placeholder="hier kommen die Adressen rein..."
        onKeyDown={(e) => handleKeyDown(e)}
        onChange={(e) => handleChange(e)}
        onPaste={(e) => handlePaste(e)}
      />
      <h6 style={{ padding: "4px", fontSize: "12px" }}>Du kannst mehrere Adressen kopieren und einfügen oder einzelne eintippen und mit Enter, Komma oder Leerzeichen bestätigen</h6>

      {error && <p className="error">{error}</p>}
    </>
  );


}
export default Hotline;