import { useState, useEffect } from "react";
import React from 'react';
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import de from 'react-phone-number-input/locale/de.json'
import Grid from '@material-ui/core/grid'
import { Link, Navigate } from "react-router-dom";
import { ItemAssignmentContext } from "twilio/lib/rest/numbers/v2/regulatoryCompliance/bundle/itemAssignment";




const ChangeableTextComp = (props) => {
  console.log(props)
  const handleDelete = () => {
    if (window.confirm('Möchtest du wirklich löschen?')) {
      console.log(props)
      let name = props.name
      const req = axios.delete(`/api/private/text/${props._id}`, {
        headers: {
          "Content-Type": "application/json",
        }
      })
        .then((response) => {
          props.setTexts(prevState => {
            const newItems = { ...prevState }
            console.log(newItems)
            console.log(props.name)
            delete newItems[props.name]
            console.log(newItems)
            return newItems
          })

        })
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }

  }


  const createText = () => {
    let content = prompt("Bitte gib hier den neuen Text ein", "");
    console.log(content)

    if (content == null || content == "") {
      content = " "
    }
    console.log(props.Text)
    let obj = {
      id: props.Text && props.Text.id ?  props.Text.id : "",
      Place: props.name,
      Language: props.language,
      Content: content
    }

    if (!props.Text) {
      const req = axios.post(`/api/private/texts`, obj, {
        headers: {
          "Content-Type": "application/json",
        }
      })
        .then((response) => {
          console.log(response.data)
          props.setTexts(prevState => {
            obj._id = response.data._id
            const newItems = { ...prevState }
            console.log(newItems)
            console.log(prevState)
            newItems[props.name] = obj
            console.log(newItems)
            return newItems
          })

        })

    } else {
      console.log(obj)
      console.log(props.Text)
      const req = axios.put(`/api/private/text/${props.Text.id}`, obj, {
        headers: {
          "Content-Type": "application/json",
        }
      })
        .then((response) => {

          props.setTexts(prevState => {
            const newItems = { ...prevState }
            console.log(newItems)
            console.log(prevState)
            newItems[props.name] = obj
            console.log(newItems)
            return newItems
          })

        })



    }




  }

  return (
    <>
      <div style={{ margin: "auto" }}>

        <span style={{ fontSize: props.fontSize }}>{props.Text && (/* !props.adminview || props.view !== false */ props.adminview || props.show !== false) ? props.Text.Content : ""}

          {props.adminview &&
            <>
              {props.br && <br />}
              {" "}
              {!props.Text && <span>Hier Text hinzufügen</span>}
              {" "}
              <i onClick={() => createText()} style={{ cursor: "pointer" }} class="fa fa-edit"></i>
              {" "}

              {/*   {props.Text && <i onClick={() => handleDelete()} style={{ cursor: "pointer" }} class="fa fa-trash" aria-hidden="true"></i>} */}
            </>}
        </span>

      </div>
    </>
  )
}
export default ChangeableTextComp;


