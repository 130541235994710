import { useState, useEffect } from "react";
import React from 'react';
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import de from 'react-phone-number-input/locale/de.json'
import Grid from '@material-ui/core/grid'
import { Link, Navigate } from "react-router-dom";
import ChangeableTextComp from "./changeableTextComp"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { parse, format, parseISO } from 'date-fns'


const Users = (props) => {
    /*   console.log(props.history) */
    const [error, setError] = useState("");
    const [privateData, setPrivateData] = useState("");
    const [received, setReceived] = useState(false)
    const [mobile, setMobile] = useState(false)
    const [mails, setMails] = useState([])
    const [expanded, setExpanded] = React.useState(false);

    const handleChangeAcc = (panel) => {
        console.log(panel)
        //setExpanded(panel)
        console.log(expanded)
        setExpanded(prevState => prevState == panel ? false : panel)
    };





    useEffect(() => {
        if (props.auth == "false") {
            console.log("pushtest")
            console.log(props.auth)
            window.location.href = '/login';
        }



    }, []);


    useEffect(() => {
        axios.get('/api/private/MailHistory', {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(el => {
            setMails(el.data)
            console.log(el.data)
            if (el.data) {
                setReceived(true)
            }
        })

    }, []);



    /* 
    
        const handleDelete = (e) => {
            if (window.confirm('Möchtest du wirklich löschen?')) {
                console.log(e)
                const req = axios.delete(`/api/private/agents/${e._id}`, {
                    headers: {
                        "Content-Type": "application/json",
                    }
                })
    
                    .then((response) => {
                        setAgents(prevState => prevState.filter(item => item._id !== e._id));
                    })
            } else {
                // Do nothing!
                console.log('Thing was not saved to the database.');
            }
    
    
        }
    
        const handleAdd = () => {
    
            let vorname = prompt("Vorname", "");
            let nachname = prompt("Nachname", "");
            let gmail = prompt("Gmail", "");
            //  let content = prompt("Bitte gib hier den neuen Text ein", ""); 
    
            if (vorname != null && nachname != null && gmail != null) {
                let obj = {
                    firstName: vorname,
                    lastName: nachname,
                    email: gmail
                }
    
    
                if (!props.Text) {
                    const req = axios.post(`/api/private/agents`, obj, {
                        headers: {
                            "Content-Type": "application/json",
                        }
                    })
                        .then((response) => {
                            console.log(response.data)
                            obj._id = response.data._id
                            setAgents(prevState => {
                                return [...prevState, obj]
                            })
                        })
                }
            } else {
                // Do nothing!
                console.log('Thing was not saved to the database.');
            }
        } */

    const abbrechen = (e) => {
        const req = axios.delete(`/api/private/MailHistory-delete/${e}`, {
            headers: {
                "Content-Type": "application/json",
            }
        })
            .then((response) => {
                setMails(prevState => prevState.filter(item => item._id !== e));
            })
    }


    const start = (e, index) => {
console.log(e)
console.log(index)
        let newitem = e
        newitem.Status = "in Arbeit"
        let newbody = {
            Status: "in Arbeit"
        }
        const req = axios.put(`/api/private/MailHistory/${e._id}`, newbody, {
            headers: {
                "Content-Type": "application/json",
            }
        })


            .then((response) => {
                setMails(prevState => {
                    const newItems = [...prevState]
                    console.log(newItems)
                    console.log(prevState)
                    newItems[index] = newitem
                    return newItems
                })
            })
    }



    if (props.auth !== "" && props.auth !== "false") {
        return (
            <div>

                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>

                    <div id="content" />
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <header>

                            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                                <div className="navbar-brand" href="#">drc-ce</div>
                                <button onClick={() => setMobile(prevState => !prevState)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>

                                <div style={{ display: mobile ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarCollapse">
                                    <ul className="navbar-nav mr-auto">
                                        {props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/hotline-admin"} >Hotline</Link>
                                            </li>
                                        ) :
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/hotline"} >Hotline</Link>
                                            </li>
                                        }

                                        {props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/bilder-admin"} >Bilder</Link>
                                            </li>
                                        ) :
                                            <li className="nav-item">
                                                <Link className="nav-link" to={"/bilder"} >Bilder</Link>
                                            </li>
                                        }

                                        {props.adminrights && props.adminview ? (
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/${props.RandomPath}-admin`} >Anrufer</Link>
                                            </li>
                                        ) : ""}
                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item ">
                                                <Link className="nav-link" to={`/user-admin`} >Agents</Link>
                                            </li>
                                        ) : ""}
                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item active">
                                                <Link className="nav-link" to={`/email`} >Email</Link>
                                            </li>
                                        ) : ""}
                                    </ul>


                                </div>
                            </nav>
                        </header>
                        <main role="main">
                            <div style={{ marginTop: 100 }} className="col-lg-10 mx-auto">



                                <button style={{ marginLeft: "20px", width: "130px", margin: "auto", display: "block", marginTop: "20px", marginBottom: 20 }} className="btn btn-primary" >

                                    <Link style={{ color: "inherit" }} to={"/send-email"} >Neue Email</Link>

                                </button>

                                {mails.map((item, index) => {
                                    return (

                                        <Accordion key={item._id} expanded={expanded === 'panel' + index} /* onChange={handleChangeAcc('panel' + index)} */>
                                            <AccordionSummary
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div style={{ alignSelf: "center" }} className="col-lg-6"> {item.Subject}</div>
                                                <div style={{ alignSelf: "center" }} className="col-lg-3"> {item.Date}</div>
                                                <div style={{ alignSelf: "center" }} className="col-lg-2">
                                                    {item.Status == "startet gleich" && <><button style={{ maxWidth: "100px" }} onClick={() => start(item, index)} className="btn btn-primary">starten</button> <button onClick={() => abbrechen(item._id)} style={{ maxWidth: "120px" }} className="btn btn-warning">abbrechen</button></>}
                                                    {item.Status == "abgebrochen" && "abgebrochen"}
                                                    {item.Status.startsWith("in Arbeit") ? item.Status : ""}
                                                    {item.Status.startsWith("erledigt") ? item.Status : ""}

                                                </div>
                                                <div  onClick={() => handleChangeAcc('panel' + index)} style={{padding: "10px", cursor: "pointer", alignSelf: "center" }} className="col-lg-1">
                                                    {expanded === 'panel' + index ? <i style={{
                                                        display: "flex",
                                                        justifyContent: "end"
                                                    }} class="fa fa-caret-down"></i> : <i style={{
                                                        display: "flex",
                                                        justifyContent: "end"
                                                    }} class="fa fa-caret-up"></i>}

                                                </div>


                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className="col-lg-7">

                                                    <b>Nachricht</b> <br />
                                                    {item.Text}
                                                    <br />
                                                    <br />
                                                    <b>Empfänger</b>
                                                    <br />
                                                   
                                                    {item.Recipients[0] !== "" ? item.Recipients.map(el => {
                                                        return (
                                                            <span>{el} <br /></span>
                                                        )
                                                    }) : 
                                                    item.CSV.length > 0 ? 
                                                    item.CSV.map(el => {
                                                        return (
                                                            <span>{el[0]} <br /></span>
                                                        )
                                                    }) : ""
                                                    }
                                                      <br />
                                                    <b>Anhang</b>
                                                    <br />
                                                    {item.Anhang[0]  ? item.Anhang.map(el => {
                                                        return (
                                                            <span>{el.originalname} <br /></span>
                                                        )
                                                    }) : ""}
                                                </div>


                                            </AccordionDetails>
                                        </Accordion>

                                    )

                                })}






                            </div>
                        </main>
                    </div>

                </div >

            </div >

        )
    } else {
        return ""
    }
}
export default Users;


