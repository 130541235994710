import {
  BrowserRouter,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import axios from "axios";
import { useState, useEffect } from "react";
import getUnixTime from 'date-fns/getUnixTime'
import { parse, format, parseISO } from 'date-fns'
import { createBrowserHistory } from 'history';
import { formatInTimeZone } from 'date-fns-tz'

// Routing

// Screens
import PrivateScreen from "./components/screens/PrivateScreen";
import Hotline from "./components/screens/HotlineAuth";
import Pictures from "./components/screens/PicturesAuth";
import Login from "./components/screens/login";
import Users from "./components/screens/Users";
import Email from "./components/screens/Email";
import EmailHistory from "./components/screens/EmailHistory";

const history = createBrowserHistory();

const App = (props) => {
  const [auth, setAuth] = useState("");
  const [admin, setAdmin] = useState(false);
  const [Texts, setTexts] = useState(false);
  useEffect(() => {
    if(auth == "") {
      axios.get('/api/private/auth', {
        headers: {
          "Content-Type": "application/json",
        }
      }).then(item => {
        console.log(item.data)
        if (item.data.success == "auth") {
          setAuth("true")
            setTexts(item.data.texts)
         } else {
          setAuth("false")
          setTexts(item.data.texts)
        }
  
        if(item.data.admin) {
          setAdmin(true)
        }
  console.log("test3")
      }).catch(e => {
        console.log(e)
      })
    }
   

  },[]);



  // format(parseISO(date), "dd-MM-yyyy")
  /*  const day = format (new Date(), 'dd')
   const month = format (new Date(), 'MM')
   const year = format (new Date(), 'yyyy')
   const hour = "04"
   const result = getUnixTime(parse(year+day+month+hour, "yyyyddMMkk", new Date())) */
  /*  let RandomPath = result*10000000000/3.141.toString(3) */
  /*  RandomPath = btoa(RandomPath) */
  let RandomPath = "MTY0NjQ5MjQ5NjU4MTAyNDMwM"
if (auth !== "") {
  return (
    <BrowserRouter>
      <div className="app">
        <Routes>
          <Route exact path={"/" + RandomPath + "-admin"} element={<PrivateScreen adminview={admin} RandomPath={RandomPath} setTexts={setTexts} Texts={Texts} dev={props.dev} adminrights={admin} />} />
          <Route exact path={"/" + RandomPath} element={<PrivateScreen RandomPath={RandomPath} Texts={Texts} setTexts={setTexts} dev={props.dev} adminrights={admin} />} />
          <Route path="/hotline-admin" element={<Hotline setTexts={setTexts} Texts={Texts} adminview={admin} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
          <Route path="/hotline" element={<Hotline setTexts={setTexts} Texts={Texts} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
          <Route path="/bilder" element={<Pictures setTexts={setTexts} Texts={Texts} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
          <Route path="/bilder-admin" element={<Pictures setTexts={setTexts} Texts={Texts} adminview={admin} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
          <Route path="/user-admin" element={<Users setTexts={setTexts} Texts={Texts} adminview={admin} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
          <Route path="/email" element={<EmailHistory setTexts={setTexts} Texts={Texts} adminview={admin} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
     
          <Route path="/send-email" element={<Email setTexts={setTexts} Texts={Texts} adminview={admin} adminrights={admin} dev={props.dev} auth={auth} RandomPath={RandomPath} history={history} />} />
        
          <Route path="/login" 
           /*   return <Redirect to='https://www.vgasdkglmjaasdgastq.com/googleauth/google'  /> */
           element={<Login auth={auth} dev={props.dev} RandomPath={RandomPath} history={history} />} />
          {/*   //"http://localhost:5000/googleauth/google"  */}
         

     </Routes>
      </div>
    </BrowserRouter>
  );
} else {
  console.log("false")
  return ""
}
 

   
  } /* else {
    return (
      <BrowserRouter>
        <div className="app">
          <Routes>
            <Route exact path={"/" + RandomPath} element={<PrivateScreen />} />
               <Route path="/hotline" element={<Hotline auth={auth} RandomPath={RandomPath}  history={history}  />} /> 
         <Route path="/bilder" element={<Pictures auth={auth} history={history}  RandomPath={RandomPath} />} />
          <Route path="/login" element={<LoginScreenPictures auth={auth}  history={history}  setAuth={setAuth}  RandomPath={RandomPath} />} />  
   
        </Routes>
        </div>
      </BrowserRouter>
    );
  } 

};*/

export default App;
