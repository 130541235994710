import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';


let dev 
let func = 'Component'
if (React[func].name === func) {
 /*  console.log("dev") */
 dev = true
 //("dev")
} else {
  //("build")
  dev= false
  console.log = () => {};
}


ReactDOM.render(
  <React.StrictMode>
    <App dev={dev} />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
