import { useState, useEffect, useCallback } from "react";
import axios from "axios";
import ZoomMtgEmbedded from "@zoomus/websdk/embedded";
import Button from '@mui/material/Button';
import { Link, Navigate } from "react-router-dom";
import ImageViewer from 'react-simple-image-viewer';
import ChangeableTextComp from "./changeableTextComp"


const Pictures = (props) => {
    const [pictures, setPictures] = useState(false);
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [images, setImages] = useState(false);
    const [first, setFirst] = useState(true);
    const [Reload, setReload] = useState(false);
    const [mobile, setMobile] = useState(false);
    const [Autodelete, setAutoDelete] = useState(false);

    const MINUTE_MS = 900000;
    /*   const MINUTE_MS = 1000  ; */
    useEffect(() => {
        if (props.auth == "false") {
            console.log("pushtest")
            console.log(props.auth)
            window.location.href = '/login';
        }
        else {
            if (first) {
                axios.get('/api/s3/pictures', {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }).then(el => {
                    console.log(el.data.success)
                    if (Array.isArray(el.data)) {
                        setPictures(el.data)

                        let images = []
                        el.data.map((item, index) => {
                            images.push(`https://drc-ce.s3.eu-central-1.amazonaws.com/${item.Key}`)
                        })
                        console.log(images)
                        setImages(images)
                        console.log(el)
                        console.log("test")
                        setFirst(false)
                    } else {
                        if (el.data.message && el.data.success == "not") {
                            console.log("test")
                            /*   props.history.push("http://localhost:5000/googleauth/google") */
                        }
                    }
                });
            }

        }
    }, []);


    useEffect(() => {
        console.log("test")
        if (props.auth == "false") {
            console.log("push")
            console.log(props.auth)
            return <Navigate to='/googleauth/google' />
            props.history.push("/googleauth/google")
        }

        else {

            console.log("test")
            const interval = setInterval(() => {
                axios.get('/api/s3/pictures', {
                    headers: {
                        "Content-Type": "application/json",
                    }
                }).then(el => {
                    setPictures(el.data)

                    let images = []
                    el.data.map((item, index) => {
                        images.push(`https://drc-ce.s3.eu-central-1.amazonaws.com/${item.Key}`)
                    })
                    console.log(images)
                    setImages(images)
                    console.log(el)
                    console.log("test")
                });
                console.log('Logs every minute');
            }, MINUTE_MS);

            return () => clearInterval(interval);
        }

    }, []);


    const reload = (e) => {
        setReload(true)
        console.log("test")
        if (props.auth == "false") {
            console.log("push")
            console.log(props.auth)
            return <Navigate to='/googleauth/google' />
            props.history.push("/googleauth/google")
        }

        else {
            axios.get('/api/s3/pictures', {
                headers: {
                    "Content-Type": "application/json",
                }
            }).then(el => {
                console.log(el.data.success)
                if (Array.isArray(el.data)) {

                    setPictures(el.data)

                    let images = []
                    el.data.map((item, index) => {
                        images.push(`https://drc-ce.s3.eu-central-1.amazonaws.com/${item.Key}`)
                    })
                    console.log(images)
                    setImages(images)
                    console.log(el)
                    console.log("test")
                    setFirst(false)
                    setReload(false)
                } else {
                    if (el.data.message && el.data.success == "not") {
                        console.log("test")
                        /*   props.history.push("http://localhost:5000/googleauth/google") */
                    }
                }
            });
        }

    }




    const delteItem = (e) => {

        let isExecuted = window.confirm("möchtest du das Bild wirklich löschen?");
        if (!isExecuted) {
            return
        }
        axios.delete(`/api/s3/pictures/${e}`, {
            headers: {
                "Content-Type": "application/json",
            }
        }).then(el => {
            console.log(el)
            if (el.status == 200) {

                setPictures(pictures.filter(item => item.Key !== e))
                //setReceived(true)
            }
        })
    }

    const rename = (e) => {
        if (e.includes('*')) {
            let newname = e.split("*")
            return newname[0]
        } else {
            return e
        }

    }

    const openImageViewer = useCallback((index) => {
        console.log(index)
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };

    const openPrompt = () => {
        let texts = props.Texts["Automatische Löschung"]["Content"]
        let name = prompt(`Automatische Löschung der Bilder nach ${texts} Tagen`);
        /*  let content = prompt("Bitte gib hier den neuen Text ein", ""); */

        if (name != null) {
            let obj = {
                Place: "Automatische Löschung",
                Language: "all",
                Content: name,
                Name: "Automatische Löschung"
            }



            const req = axios.put(`/api/private/text/${props.Texts["Automatische Löschung"]["id"]}`, obj, {
                headers: {
                    "Content-Type": "application/json",
                }
            })
                .then((response) => {

                    props.setTexts(prevState => {
                        const newItems = { ...prevState }
                        console.log(newItems)
                        console.log(prevState)
                        newItems["Automatische Löschung"] = obj
                        console.log(newItems)
                        return newItems
                    })

                })





        } else {
            // Do nothing!
            console.log('Thing was not saved to the database.');
        }


    }


    if (props.auth !== "" && props.auth !== "false" && props.Texts) {


        return (
            <>


                <div style={{ display: "flex", justifyContent: "center", position: "absolute", zIndex: 1000 }} id="meetingSDKElement"></div>
                <div style={{ width: "100vw" }} ></div>

                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
                        <header>
                            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                                <div className="navbar-brand " href="#">drc-ce</div>
                                <button onClick={() => setMobile(prevState => !prevState)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                                    aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="navbar-toggler-icon"></span>
                                </button>
                                {/*  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
          aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button> */}
                                <div style={{ display: mobile ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarCollapse">

                                    <ul className="navbar-nav mr-auto">
                                        {props.adminview ? (
                                            <li className="nav-item">
                                                <Link auth={props.auth} className="nav-link" to={"/hotline-admin"} >Hotline</Link>
                                            </li>)
                                            :
                                            <li className="nav-item">
                                                <Link auth={props.auth} className="nav-link" to={"/hotline"} >Hotline</Link>
                                            </li>}


                                        {props.adminview ? (
                                            <li className="nav-item active">
                                                <Link auth={props.auth} className="nav-link" to={"/bilder-admin"} >Bilder</Link>
                                            </li>)
                                            :
                                            <li className="nav-item active">
                                                <Link auth={props.auth} className="nav-link " to={"/bilder"} >Bilder</Link>
                                            </li>
                                        }
                                        {props.adminrights && props.adminview ? (
                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/${props.RandomPath}-admin`} >Anrufer</Link>
                                            </li>
                                        ) : ""}


                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/user-admin`} >Agents</Link>
                                            </li>
                                        ) : ""}

                                        {props.adminrights && props.adminview ? (

                                            <li className="nav-item">
                                                <Link className="nav-link" to={`/email`} >Email</Link>
                                            </li>
                                        ) : ""}

                                    </ul>
                                    <ul class="navbar-nav ml-auto">
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/bilder"} >Hotline View</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link" to={"/bilder-admin"} >Admin View</Link>
                                        </li>
                                    </ul>



                                </div>




                                {isViewerOpen && (
                                    <ImageViewer
                                        src={images}
                                        currentIndex={currentImage}
                                        disableScroll={true}
                                        closeOnClickOutside={true}
                                        onClose={closeImageViewer}
                                    />
                                )}
                            </nav>
                        </header>




                        <div className="mt-4 mb-4" id="content" />

                        {Reload ?
                            <div style={{
                                width: "100%", height: "100%", position: "absolute", backgroundColor: "#e6e6e6", zIndex: 999, overflow: "hidden",
                                display: "flex",
                                alignItems: "center"
                            }}>
                                <div class="loader">Loading...</div>
                            </div>

                            :

                            <div className="mt-4 mb-4 container">

                                <h1 className="fw-light text-center text-lg-start mt-4 mb-0">Bilder
                                    {props.adminview && (
                                        <button onClick={() => openPrompt()} style={{ marginLeft: "20px", width: "130px", margin: "auto", display: "block", marginTop: "20px" }} className="btn btn-primary" >

                                            Einstellungen

                                        </button>
                                    )}
                                </h1>
                                <button style={{ marginLeft: "20px", width: "130px", margin: "auto", display: "block", marginTop: "20px" }} onClick={() => reload()} className="btn btn-primary" >

                                    Neu Laden

                                </button>



                                <h5 className="fw-light text-center text-lg-start mt-2 mb-4">
                                    <ChangeableTextComp language={"all"} setTexts={props.setTexts} name={"BilderHeader"} adminview={props.adminview} fontSize={20} Text={props.Texts.BilderHeader} />

                                </h5>

                                <div className="row text-center text-lg-start">

                                    {pictures && Array.isArray(pictures) && pictures.map((item, index) => {
                                        return (
                                            <div className="col-lg-3 col-md-4 col-6 mb-5">
                                                <div style={{ position: "relative", fontSize: "20px", cursor: "pointer", top: "5px", textAlign: "right" }}></div>

                                                <a style={{ cursor: "unset" }} href="#" className="d-block  h-100">
                                                    <img onClick={() => openImageViewer(index)} style={{ cursor: "inherit" }} className="img-fluid img-thumbnail" src={`https://drc-ce.s3.eu-central-1.amazonaws.com/${item.Key}`} />
                                                    <h6 className="mt-2 notLink" style={{ cursor: "inherig", color: "black" }}>{rename(item.Key)}</h6>
                                                    <div className="just" style={{ display: "flex", justifyContent: "space-evenly" }}>
                                                        <a href={`https://drc-ce.s3.eu-central-1.amazonaws.com/${item.Key}`} download>
                                                            <button className="justBut btn btn-primary but" >

                                                                Speichern

                                                            </button>

                                                        </a>
                                                        <button onClick={() => delteItem(item.Key)} className="justBut btn btn-primary but" >
                                                            Löschen
                                                        </button>
                                                    </div>
                                                </a>


                                            </div>
                                        )
                                    })}


                                </div>
                            </div>

                        }
                    </div>










                </div>
            </>
        )

    } else {
        return ""
    }

};

export default Pictures;
