import { useState, useEffect } from "react";
import React from 'react';
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import de from 'react-phone-number-input/locale/de.json'
import Grid from '@material-ui/core/grid'
import { Link, Navigate } from "react-router-dom";
import ChangeableTextComp from "./changeableTextComp"



const Hotline = (props) => {
  /*   console.log(props.history) */
  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");
  const [Radio, setRadio] = useState(false);
  const [RadioTwo, setRadioTwo] = useState(false);
  const [message, setMessage] = useState();
  const [value, setValue] = useState()
  const [received, setReceived] = useState(false)
  const [Signature, setSignature] = useState(false)
  const [focused, setFocused] = React.useState(false)
  const [Counter, setCounter] = useState(0)
  const [CounterTwo, setCounterTwo] = useState(0)
  const [mobile, setMobile] = useState(false)
  const [Vorlagen, setVorlagen] = useState([])
  const [active, setActive] = useState(false)
  const [sent, setSent] = useState(false)
  const [activeMessage, setActiveMessage] = useState("")
  const onFocus = () => setFocused(true)
  const onBlur = () => setFocused(false)

  const calcLength = (e) => {
    if (isGsmMessage(e)) {
      if (e.length > 160) {
        setCounter(Math.ceil(e.length / 153))
      } else {
        setCounter(1)
      }

    } else {
      if (e.length > 70) {
        setCounter(Math.ceil(e.length / 67))
      } else {
        setCounter(1)
      }

    }
  }

  const calcLengthTwo = (e) => {
    if (isGsmMessage(e)) {
      if (e.length > 160) {
        setCounterTwo(Math.ceil(e.length / 153))
      } else {
        setCounterTwo(1)
      }

    } else {
      if (e.length > 70) {
        setCounterTwo(Math.ceil(e.length / 67))
      } else {
        setCounterTwo(1)
      }

    }
  }

  const handleChange = (e) => {
    setMessage(e.target.value)
    calcLength(e.target.value)

  }


  const handleActiveChange = (e) => {
    setActiveMessage(e.target.value)
    calcLengthTwo(e.target.value)

  }

  useEffect(() => {
    if (props.auth == "false") {
      console.log("pushtest")
      console.log(props.auth)
      window.location.href = '/login';
    }



  }, []);


  const gsmCodePoints = new Set([
    0x000a, 0x000c, 0x000d,
    0x0020, 0x0021, 0x0022, 0x0023, 0x0024, 0x0025, 0x0026, 0x0027, 0x0028, 0x0029, 0x002a, 0x002b, 0x002c, 0x002d, 0x002e, 0x002f,
    0x0030, 0x0031, 0x0032, 0x0033, 0x0034, 0x0035, 0x0036, 0x0037, 0x0038, 0x0039, 0x003a, 0x003b, 0x003c, 0x003d, 0x003e, 0x003f,
    0x0040, 0x0041, 0x0042, 0x0043, 0x0044, 0x0045, 0x0046, 0x0047, 0x0048, 0x0049, 0x004a, 0x004b, 0x004c, 0x004d,
    0x004e, 0x004f,
    0x0050, 0x0051, 0x0052, 0x0053, 0x0054, 0x0055, 0x0056, 0x0057, 0x0058, 0x0059, 0x005a, 0x005b, 0x005c, 0x005d, 0x005e, 0x005f,
    0x0061, 0x0062, 0x0063, 0x0064, 0x0065, 0x0066, 0x0067, 0x0068, 0x0069, 0x006a, 0x006b, 0x006c, 0x006d, 0x006e, 0x006f,
    0x0070, 0x0071, 0x0072, 0x0073, 0x0074, 0x0075, 0x0076, 0x0077, 0x0078, 0x0079, 0x007a, 0x007b, 0x007c, 0x007d, 0x007e,
    0x00a1, 0x00a3, 0x00a4, 0x00a5, 0x00a7,
    0x00bf,
    0x00c4, 0x00c5, 0x00c6, 0x00c7, 0x00c9,
    0x00d1, 0x00d6, 0x00d8, 0x00dc, 0x00df,
    0x00e0, 0x00e4, 0x00e5, 0x00e6, 0x00e8, 0x00e9, 0x00ec,
    0x00f1, 0x00f2, 0x00f6, 0x00f8, 0x00f9, 0x00fc,
    0x0393, 0x0394, 0x0398, 0x039b, 0x039e, 0x03a0, 0x03a3, 0x03a6, 0x03a8, 0x03a9,
    0x20ac,
  ]);

  function isGsmMessage(message) {
    for (const s of message) {
      const codePoint = s.codePointAt(0);
      if (codePoint && !gsmCodePoints.has(codePoint)) {
        return false;
      }
    }
    return true;
  }


  const handleSend = (e) => {
    setSent(true)
    console.log(value)
    let file = {

      message: message,
      to: value,
      way: RadioTwo
    }
    axios.post('/api/private/message', file, {
      headers: {
        "Content-Type": "application/json",
      }
    }).then(el => {
      console.log(el)
      if (el.data.success == true) {
        setReceived(true)
      }
    })
  }


  const newMessages = (e) => {
    setSent(false)
    setReceived(false)
    setRadioTwo(false)
    setRadio(false)
    setValue(false)
  }


  const changeLanguage = (e) => {
    /*   let test = Object.values(props.Texts)
      test = test.filter(item => {
        return item.Language == e
      });
      console.log(test)
      setVorlagen(test) */
    setActive(false)
    setRadio(e)
    setRadioTwo("SMS")
    if (e == "Deutsch") {
      setMessage(props.Texts["Standard-De"].Content)

    } else if (e == "Englisch") {
      setMessage(props.Texts["Standard-E"].Content)
    } else if (e == "Russisch") {
      setMessage(props.Texts["Standard-Ru"].Content)
    }
    /*  if (e == "Deutsch") {
 
       setMessage(`Hallo Lieber Bruder, Liebe Schwester, Bitte gehe auf: https://drc-ce.de/${props.RandomPath} Für Video/Bilder - Übertragung, Deine Brüder vom drc-ce`)
       calcLength(`Hallo Lieber Bruder, Liebe Schwester, Bitte gehe auf: https://drc-ce.de/${props.RandomPath} Für Video/Bilder - Übertragung, Deine Brüder vom drc-ce`)
     } else if (e == "Englisch") {
       setMessage(`Hello Dear Brother, Dear Sister, Please go to: https://drc-ce.de/${props.RandomPath} For video/pictures - transmission, Your brothers from drc-ce`)
       calcLength(`Hello Dear Brother, Dear Sister, Please go to: https://drc-ce.de/${props.RandomPath} For video/pictures - transmission, Your brothers from drc-ce`)
     } else if (e == "Russisch") {
       setMessage(`Здравствуйте, дорогой брат, дорогая сестра, Пожалуйста, зайдите на сайт: https://drc-ce.de/${props.RandomPath} Для видео/фотографий - передача, Ваши братья из drc-ce`)
       calcLength(`Здравствуйте, дорогой брат, дорогая сестра, Пожалуйста, зайдите на сайт: https://drc-ce.de/${props.RandomPath} Для видео/фотографий - передача, Ваши братья из drc-ce`)
     }
  */

  }



  useEffect(() => {
    let test = Object.values(props.Texts)
    test = test.filter(item => {
      return item.Language == Radio
    });
    console.log(test)
    setVorlagen(test)

  }, [Radio, props.Texts]);


  const handleNeu = () => {
    let name = prompt("Bitte gib hier den Namen der Vorlage ein", "");
    /*  let content = prompt("Bitte gib hier den neuen Text ein", ""); */

    if (name != null) {
      let obj = {
        Place: name,
        Language: Radio,
        Content: " ",
        Name: name
      }


      if (!props.Text) {
        const req = axios.post(`/api/private/texts`, obj, {
          headers: {
            "Content-Type": "application/json",
          }
        })
          .then((response) => {

            props.setTexts(prevState => {
              obj.id = response.data._id
              const newItems = { ...prevState }
              console.log(newItems)
              console.log(prevState)
              newItems[name] = obj
              console.log(newItems)
              return newItems
            })

          })

      }



    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }


  }


  const newMessagesSave = (e) => {
    console.log(activeMessage)
    console.log(active)
    let obj = {
      Place: active.Name,
      Language: Radio,
      Content: activeMessage,
      id: active.id,
      Name: active.Name
    }
    console.log(obj)
    const req = axios.put(`/api/private/text/${active.id}`, obj, {
      headers: {
        "Content-Type": "application/json",
      }
    })
      .then((response) => {
        console.log(response)
        props.setTexts(prevState => {
          const newItems = { ...prevState }
          console.log(newItems)
          console.log(prevState)
          newItems[active.Name] = obj
          console.log(newItems)
          return newItems
        })

      })



  }

  const setActiveFunction = (e) => {
    console.log(e)
    setActive(e)
    setActiveMessage(e.Content)
    calcLengthTwo(activeMessage)
  }

  const MessageDelete = (e) => {
    console.log(active)
    if (window.confirm('Möchtest du wirklich löschen?')) {
      console.log(props)
      let name = props.name
      const req = axios.delete(`/api/private/text/${active.id}`, {
        headers: {
          "Content-Type": "application/json",
        }
      })
        .then((response) => {
          props.setTexts(prevState => {
            const newItems = { ...prevState }
            console.log(newItems)
            console.log(active)
            delete newItems[active.Name]
            console.log(newItems)
            return newItems
          })

        })
    } else {
      // Do nothing!
      console.log('Thing was not saved to the database.');
    }


  }


  const setHotlineMessage = (e) => {
    console.log(e)
    setMessage(e.Content)
    calcLength(e.Content)
  }

  if (props.auth !== "" && props.auth !== "false" && props.Texts) {
    return (
      <div>

        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>

          <div id="content" />
          <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
            <header>

              <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
                <div className="navbar-brand" href="#">drc-ce</div>
                <button onClick={() => setMobile(prevState => !prevState)} class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
                  aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                  <span class="navbar-toggler-icon"></span>
                </button>

                <div style={{ display: mobile ? 'block' : 'none' }} className="collapse navbar-collapse" id="navbarCollapse">
                  <ul className="navbar-nav mr-auto">
                    {props.adminview ? (

                      <li className="nav-item active">
                        <Link className="nav-link" to={"/hotline-admin"} >Hotline</Link>
                      </li>
                    ) :
                      <li className="nav-item active">
                        <Link className="nav-link" to={"/hotline"} >Hotline</Link>
                      </li>
                    }

                    {props.adminview ? (

                      <li className="nav-item">
                        <Link className="nav-link" to={"/bilder-admin"} >Bilder</Link>
                      </li>
                    ) :
                      <li className="nav-item">
                        <Link className="nav-link" to={"/bilder"} >Bilder</Link>
                      </li>
                    }



                    {props.adminrights && props.adminview ? (
                      <li className="nav-item">
                        <Link className="nav-link" to={`/${props.RandomPath}-admin`} >Anrufer</Link>
                      </li>
                    ) : ""}

                    {props.adminrights && props.adminview ? (

                      <li className="nav-item">
                        <Link className="nav-link" to={`/user-admin`} >Agents</Link>
                      </li>
                    ) : ""}
                    {props.adminrights && props.adminview ? (

                      <li className="nav-item">
                        <Link className="nav-link" to={`/email`} >Email</Link>
                      </li>
                    ) : ""}
                  </ul>
                  {props.adminrights && (
                    <ul class="navbar-nav ml-auto">
                      <li className="nav-item">
                        <Link className="nav-link" to={"/hotline"} >Hotline View</Link>
                      </li>
                      <li className="nav-item">
                        <Link className="nav-link" to={"/hotline-admin"} >Admin View</Link>
                      </li>
                    </ul>
                  )}


                </div>
              </nav>
            </header>
            <main role="main">
              <div style={{ marginTop: 100 }} className="col-lg-4 mx-auto">


                <div className="row align-items-start" >


                  <ChangeableTextComp language={"all"} setTexts={props.setTexts} name={"hotlineHeader"} adminview={props.adminview} fontSize={20} Text={props.Texts.hotlineHeader} />

                </div>
                <div className="row align-items-start" >
                  <div style={{ display: "flex", justifyContent: "space-evenly" }} className="col-sm-10 col-md-7 col-lg-12 mx-auto">
                    <button onClick={(e) => changeLanguage("Deutsch")} style={{ opacity: Radio == "Deutsch" ? '0.7' : '1' }} className="mt-2 btn btn-primary but"  >
                      Deutsch
                    </button>
                   {/*  <button onClick={(e) => changeLanguage("Englisch")} style={{ opacity: Radio == "Englisch" ? '0.7' : '1' }} className="mt-2 btn btn-primary but" >
                      English
                    </button> */}
                    <button onClick={(e) => changeLanguage("Russisch")} style={{ opacity: Radio == "Russisch" ? '0.7' : '1' }} className="mt-2 btn btn-primary but"  >
                      Русский
                    </button>





                  </div>

                </div>
                {/*    {Radio &&
                <div className="row">
                  <div style={{ display: "flex", justifyContent: "space-evenly" }} className="col-sm-10 col-md-7 col-lg-12 mx-auto">
                    <div >
                      <input type="radio" name="type4"
                        value={"SMS"}
                        checked={RadioTwo == "SMS"}
                        onChange={(e) => setRadioTwo("SMS")}
                      /><span className="spanVersioning"> SMS</span> </div>
                    <div >
                      <input type="radio" name="typ5"
                        value={"WhatsApp"}
                        checked={RadioTwo == "WhatsApp"}
                        onChange={(e) => setRadioTwo("WhatsApp")}
                      /><span className="spanVersioning"> WhatsApp</span> </div>

                   
                  </div>
                </div>
              } */}

                {RadioTwo &&
                  <>
                    <div className="row">



                      {Radio == "Deutsch" ?
                        <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                          <ChangeableTextComp br={true} language={"all"} setTexts={props.setTexts} name={"HotlineDeutsch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineDeutsch} />
                        </h5>
                        /* : Radio == "Englisch" ?
                          <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                            <ChangeableTextComp br={true} language={"all"} setTexts={props.setTexts} name={"HotlineEnglisch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineEnglisch} />
                          </h5> */

                          : Radio == "Russisch" ?
                            <h5 style={{ margin: "Auto" }} className="fw-light text-center text-lg-start mt-2 mb-4">
                              <ChangeableTextComp br={true} language={"all"} setTexts={props.setTexts} name={"HotlineRussisch"} adminview={props.adminview} fontSize={20} Text={props.Texts.HotlineRussisch} />
                            </h5>


                            : ""
                      }
                    </div>
                    <div style={{ justifyContent: "center" }} className="row">


                      {!props.adminview ?
                        <>

                          {Vorlagen.map((item, index) => {
                            return (
                              <button style={{ width: "unset" }} onClick={() => setHotlineMessage(item)} className="m-2 btn btn-primary" variant="contained" >
                                <i class="fa fa-edit"></i>
                                {" "} {item.Name}
                              </button>
                            )

                          })}
                        </> : ""}
                    </div>
                    <div style={{ justifyContent: "center" }} className="row" >
                      {props.adminview ?
                        <>
                          <button style={{ width: "unset" }} onClick={() => handleNeu()} className="m-2 btn btn-primary" variant="contained" >
                            <i class="fa fa-plus"></i>
                            {" "}Neu
                          </button>
                          {Vorlagen.map((item, index) => {
                            return (
                              <button style={{ width: "unset" }} onClick={() => setActiveFunction(item)} className="m-2 btn btn-primary" variant="contained" >
                                <i class="fa fa-edit"></i>
                                {" "} {item.Name}
                              </button>
                            )

                          })}


                          {active &&
                            <div className="col-sm-8 col-md-6 col-lg-10 mx-auto">
                              <Box
                                component="form"
                                sx={{
                                  '& .MuiTextField-root': { m: 1, mt: 3, width: '25ch' },
                                }}
                                noValidate
                                autoComplete="off"
                              >



                                <TextField
                                  inputProps={{ spellCheck: 'false' }}
                                  id="outlined-multiline-static"

                                  multiline
                                  rows={6}
                                  value={activeMessage}
                                  onChange={(e) => handleActiveChange(e)}
                                  variant="filled"
                                />

                                <span style={{
                                  marginRight: "auto",
                                  display: "flex",
                                  justifyContent: "end"
                                }}>Anzahl SMS {CounterTwo}
                                </span>

                              </Box>
                              <Box
                                sx={{ mt: 2 }}
                              >



                              </Box>
                              <Grid container justify="flex-end">


                                <button style={{ width: "unset" }} onClick={() => newMessagesSave()} className="m-2 btn btn-primary" variant="contained" >

                                  Speichern
                                </button>
                                {active.Name !== "Standard-De" && active.Name !== "Standard-Ru" && active.Name !== "Standard-E" ?
                                  <button style={{ width: "unset" }} onClick={() => MessageDelete()} className="m-2 btn btn-primary" variant="contained" >

                                    Löschen
                                  </button>
                                  : ""}


                              </Grid>






                            </div>
                          }

                        </>


                        :

                        <div className="col-sm-8 col-md-6 col-lg-10 mx-auto">
                          <Box
                            component="form"
                            sx={{
                              '& .MuiTextField-root': { m: 1, mt: 3, width: '25ch' },
                            }}
                            noValidate
                            autoComplete="off"
                          >



                            <TextField
                              inputProps={{ spellCheck: 'false' }}
                              id="outlined-multiline-static"

                              multiline
                              rows={6}
                              value={message}
                              onChange={(e) => handleChange(e)}
                              variant="filled"
                            />

                            {/*  <span style={{
                              marginRight: "auto",
                              display: "flex",
                              justifyContent: "end"
                            }}>Anzahl SMS {Counter}
                            </span> */}

                          </Box>
                          <Box
                            sx={{ mt: 2 }}
                          >
                            <div style={{ marginLeft: "13px" }}>
                              {focused &&

                                <span style={{ paddingLeft: "10px" }}>Beispiel : +49 123 4567890</span>
                              }
                            </div>


                            <PhoneInput
                              international={true}
                              countryOptionsOrder={["DE", "PL", "UA", "|",]}
                              defaultCountry="de"
                              /*  countries={["RU", "UA", "KZ"]} */
                              labels={de}
                              country="de"
                              placeholder="Hier Telefonnummer eingeben"
                              value={value}
                              onChange={setValue}
                              onFocus={onFocus} onBlur={onBlur}


                            />
                          </Box>
                          <Grid container justify="flex-end">

                            {!received && sent ?
                              <></>

                              :
                              !received ?
                                <button onClick={() => handleSend()} className="mt-2 btn btn-primary but" /* variant="contained" */ >
                                  <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                  Senden
                                </button>


                                :
                                <>
                                  <button onClick={() => newMessages()} style={{ width: "200px", backgroundColor: "#2fbf4d" }} className="mt-2 btn btn-primary"  >
                                    Erfolgreich gesendet <br />
                                    Neue Nachricht Senden
                                  </button>
                                </>
                            }
                          </Grid>






                        </div>
                      }

                    </div>

                  </>}


              </div>
            </main>
          </div>

        </div ></div >


    )
  } else {
    return ""
  }
}
export default Hotline;


