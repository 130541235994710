import { useState, useEffect } from "react";
import axios from "axios";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import de from 'react-phone-number-input/locale/de.json'
import Grid from '@material-ui/core/grid'
import { Link, Navigate } from "react-router-dom";



const Login =  (props) => {
   console.log(props) 

  /*   console.log(props.history) */
  const [error, setError] = useState("");
  const [privateData, setPrivateData] = useState("");
  const [Radio, setRadio] = useState("Deutsch");
  const [RadioTwo, setRadioTwo] = useState(true);
  const [message, setMessage] = useState('Hallo liebe Schwester, hallo lieber Bruder, um noch mehr elektronische Sicherheit zu gewährleisten, haben wir  das Login-Verfahren umgestellt. Bitte klickt auf Login und benutzt dann dieselbe Email-Addresse wie für Appsheet.');
  const [value, setValue] = useState()
  const [received, setReceived] = useState(false)
  const [Signature, setSignature] = useState(false)


  useEffect(() => {   
    if (props.auth == "true")
    { 
    window.location.href = '/hotline';  }
});

  const handleChange = (e) => {
    setMessage(e.target.value)
  }

  const handleURL = (e) => {
     if ( props.dev) {
       console.log("test1")
       window.location.href = 'http://localhost:3000/googleauth/google';  
    } else {
      console.log("test2")
        window.location.href = '/googleauth/google';  
    } 
  }
  
  

  const changeLanguage = (e) => {
    setRadio(e)
    setRadioTwo("SMS")
    if (e == "Deutsch") {

      setMessage(`Hallo liebe Schwester,
      hallo lieber Bruder, 
      um noch mehr elektronische Sicherheit zu gewährleisten, haben wir  das Login-Verfahren umgestellt. 
      Bitte klickt auf Login und benutzt dann dieselbe Email-Addresse wie für Appsheet.     
      `)
    } else if (e == "Englisch") {
      setMessage(`Hello Dear Brother, Dear Sister, 
      for added security we have changed the way you log in. 
      Please click login and then use the same email-address as for Appsheet.`)
    } else if (e == "Russisch") {
      setMessage(`Здравствуй, дорогая сестра, дорогой брат, 
      Чтобы обеспечить еще большую безопасность, мы изменили процедуру входа в систему. 
      Пожалуйста, нажмите на Login и используйте тот же адрес электронной почты, что и для Appsheet.`)
    }
  }
  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
        <div id="content" />
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, height: '100%' }}>
          <header>
            <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
              <div className="navbar-brand" href="#">drc-ce</div>
              <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav mr-auto">
                  <li className="nav-item active">
                    <Link className="nav-link" to={"/Hotline"} >Hotline</Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to={"/Bilder"} >Bilder</Link>
                  </li>
                </ul>
              </div>
            </nav>
          </header>
          <main role="main">
            <div style={{ marginTop: 100 }} className="col-lg-4 mx-auto">
              <div className="row align-items-start" >
                <div style={{ display: "flex", justifyContent: "space-evenly" }} className="col-sm-10 col-md-7 col-lg-12 mx-auto">
                  <button onClick={(e) => changeLanguage("Deutsch")} style={{ opacity: Radio == "Deutsch" ? '0.7' : '1' }} className="mt-2 btn btn-primary but"  >
                    Deutsch
                  </button>
                 {/*  <button onClick={(e) => changeLanguage("Englisch")} style={{ opacity: Radio == "Englisch" ? '0.7' : '1' }} className="mt-2 btn btn-primary but" >
                    English
                  </button> */}
                  <button onClick={(e) => changeLanguage("Russisch")} style={{ opacity: Radio == "Russisch" ? '0.7' : '1' }} className="mt-2 btn btn-primary but"  >
                    Русский
                  </button>
                </div>
              </div>
              {RadioTwo &&
                <div className="row">
                  <div className="mt-4 col-sm-8 col-md-6 col-lg-10 mx-auto">    
                    <Grid container justify="center">
                    <div style={{textAlign: "center"}}>{message}</div>
                        <button  onClick={()=>handleURL()} className="mt-4 btn btn-primary but" variant="contained" >
                          <i className="fa fa-sign-in" aria-hidden="true"></i> Login
                        </button>
                    </Grid>
                  </div>
                </div>}
            </div>
          </main>
        </div>

      </div ></div >

                  
  )
                /*   } else {
                    return ""
                  } */
}
export default Login;


